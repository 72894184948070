// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyBDUU-Huc_876q_0Z62SoRWzOikGQbpqVg",
  authDomain: "the-greens-golf-club-86ff3.firebaseapp.com",
  databaseURL: "https://the-greens-golf-club-86ff3-default-rtdb.firebaseio.com",
  projectId: "the-greens-golf-club-86ff3",
  storageBucket: "the-greens-golf-club-86ff3.appspot.com",
  messagingSenderId: "974411742887",
  appId: "1:974411742887:web:afa8771784964a1e19e582",
  measurementId: "G-LE2W9SPQK0",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getDatabase(app);
