import React from "react";
import { Switch, Route } from "react-router-dom";
import { Navbar, Home, Share, Header, Footer } from "./components";
import { ToastContainer } from "react-toastify";
import AOS from "aos";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "./common.css";
import "aos/dist/aos.css";
import "react-toastify/dist/ReactToastify.css";

const App = () => {
  AOS.init({
    once: true,
  });

  return (
    <>
      <Navbar />
      <Header />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/:id" component={Share} />
      </Switch>
      <Footer />
      <ToastContainer
        position="bottom-right"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default App;
