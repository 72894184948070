export const JoinWaitlistBulbIcon = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="102"
        height="102"
        fill="none"
        viewBox="0 0 102 102"
      >
        <ellipse
          cx="100.259"
          cy="51.002"
          fill="#000"
          fillOpacity="0.12"
          rx="1.407"
          ry="1.407"
        ></ellipse>
        <ellipse
          cx="1.74"
          cy="51.002"
          fill="#000"
          fillOpacity="0.12"
          rx="1.407"
          ry="1.407"
        ></ellipse>
        <circle
          cx="93.222"
          cy="51.002"
          r="2.815"
          fill="#000"
          fillOpacity="0.48"
        ></circle>
        <circle
          cx="8.778"
          cy="51.002"
          r="2.815"
          fill="#000"
          fillOpacity="0.48"
        ></circle>
        <ellipse
          fill="#000"
          fillOpacity="0.12"
          rx="1.407"
          ry="1.407"
          transform="rotate(-15 194.57 -355.271)"
        ></ellipse>
        <ellipse
          cx="3.42"
          cy="63.751"
          fill="#000"
          fillOpacity="0.12"
          rx="1.407"
          ry="1.407"
          transform="rotate(-15 3.42 63.751)"
        ></ellipse>
        <ellipse
          cx="82.267"
          cy="42.624"
          fill="#000"
          fillOpacity="0.8"
          rx="4.222"
          ry="4.222"
          transform="rotate(-15 82.267 42.624)"
        ></ellipse>
        <ellipse
          cx="19.734"
          cy="59.38"
          fill="#000"
          fillOpacity="0.8"
          rx="4.222"
          ry="4.222"
          transform="rotate(-15 19.734 59.38)"
        ></ellipse>
        <ellipse
          fill="#000"
          fillOpacity="0.12"
          rx="1.407"
          ry="1.407"
          transform="rotate(-30 96.041 -161.587)"
        ></ellipse>
        <ellipse
          fill="#000"
          fillOpacity="0.12"
          rx="1.407"
          ry="1.407"
          transform="rotate(-30 145.3 22.254)"
        ></ellipse>
        <ellipse
          fill="#000"
          fillOpacity="0.48"
          rx="2.815"
          ry="2.815"
          transform="rotate(-30 99.558 -148.453)"
        ></ellipse>
        <ellipse
          cx="14.434"
          cy="72.112"
          fill="#000"
          fillOpacity="0.48"
          rx="2.815"
          ry="2.815"
          transform="rotate(-30 14.434 72.112)"
        ></ellipse>
        <circle
          r="1.407"
          fill="#000"
          fillOpacity="0.12"
          transform="rotate(-45 62.433 -95.521)"
        ></circle>
        <circle
          r="1.407"
          fill="#000"
          fillOpacity="0.12"
          transform="rotate(-45 111.693 23.4)"
        ></circle>
        <ellipse
          cx="73.888"
          cy="28.112"
          fill="#000"
          fillOpacity="0.8"
          rx="4.222"
          ry="4.222"
          transform="rotate(-45 73.888 28.112)"
        ></ellipse>
        <ellipse
          cx="28.109"
          cy="73.89"
          fill="#000"
          fillOpacity="0.8"
          rx="4.222"
          ry="4.222"
          transform="rotate(-45 28.109 73.89)"
        ></ellipse>
        <ellipse
          fill="#000"
          fillOpacity="0.12"
          rx="1.407"
          ry="1.407"
          transform="rotate(-60 45.038 -61.327)"
        ></ellipse>
        <ellipse
          fill="#000"
          fillOpacity="0.12"
          rx="1.407"
          ry="1.407"
          transform="rotate(-60 94.298 23.992)"
        ></ellipse>
        <ellipse
          cx="72.111"
          cy="14.435"
          fill="#000"
          fillOpacity="0.48"
          rx="2.815"
          ry="2.815"
          transform="rotate(-60 72.11 14.435)"
        ></ellipse>
        <ellipse
          cx="29.889"
          cy="87.565"
          fill="#000"
          fillOpacity="0.48"
          rx="2.815"
          ry="2.815"
          transform="rotate(-60 29.89 87.565)"
        ></ellipse>
        <ellipse
          cx="63.748"
          cy="3.42"
          fill="#000"
          fillOpacity="0.12"
          rx="1.407"
          ry="1.407"
          transform="rotate(-75 63.748 3.42)"
        ></ellipse>
        <ellipse
          fill="#000"
          fillOpacity="0.12"
          rx="1.407"
          ry="1.407"
          transform="rotate(-75 83.362 24.367)"
        ></ellipse>
        <ellipse
          cx="59.376"
          cy="19.733"
          fill="#000"
          fillOpacity="0.8"
          rx="4.222"
          ry="4.222"
          transform="rotate(-75 59.376 19.733)"
        ></ellipse>
        <ellipse
          cx="42.621"
          cy="82.268"
          fill="#000"
          fillOpacity="0.8"
          rx="4.222"
          ry="4.222"
          transform="rotate(-75 42.621 82.268)"
        ></ellipse>
        <ellipse
          cx="50.999"
          cy="1.741"
          fill="#000"
          fillOpacity="0.12"
          rx="1.407"
          ry="1.407"
          transform="rotate(-90 51 1.741)"
        ></ellipse>
        <ellipse
          cx="50.999"
          cy="100.26"
          fill="#000"
          fillOpacity="0.12"
          rx="1.407"
          ry="1.407"
          transform="rotate(-90 51 100.26)"
        ></ellipse>
        <ellipse
          cx="50.999"
          cy="8.778"
          fill="#000"
          fillOpacity="0.48"
          rx="2.815"
          ry="2.815"
          transform="rotate(-90 51 8.778)"
        ></ellipse>
        <ellipse
          cx="50.999"
          cy="93.223"
          fill="#000"
          fillOpacity="0.48"
          rx="2.815"
          ry="2.815"
          transform="rotate(-90 51 93.223)"
        ></ellipse>
        <ellipse
          cx="38.249"
          cy="3.42"
          fill="#000"
          fillOpacity="0.12"
          rx="1.407"
          ry="1.407"
          transform="rotate(-105 38.25 3.42)"
        ></ellipse>
        <ellipse
          fill="#000"
          fillOpacity="0.12"
          rx="1.407"
          ry="1.407"
          transform="rotate(-105 69.696 24.833)"
        ></ellipse>
        <ellipse
          cx="42.621"
          cy="19.733"
          fill="#000"
          fillOpacity="0.8"
          rx="4.222"
          ry="4.222"
          transform="rotate(-105 42.621 19.733)"
        ></ellipse>
        <ellipse
          cx="59.376"
          cy="82.268"
          fill="#000"
          fillOpacity="0.8"
          rx="4.222"
          ry="4.222"
          transform="rotate(-105 59.376 82.268)"
        ></ellipse>
        <ellipse
          cx="26.371"
          cy="8.341"
          fill="#000"
          fillOpacity="0.12"
          rx="1.407"
          ry="1.407"
          transform="rotate(-120 26.37 8.34)"
        ></ellipse>
        <ellipse
          fill="#000"
          fillOpacity="0.12"
          rx="1.407"
          ry="1.407"
          transform="rotate(-120 64.852 24.998)"
        ></ellipse>
        <circle
          cx="29.89"
          cy="14.435"
          r="2.815"
          fill="#000"
          fillOpacity="0.48"
          transform="rotate(-120 29.89 14.435)"
        ></circle>
        <ellipse
          cx="72.111"
          cy="87.566"
          fill="#000"
          fillOpacity="0.48"
          rx="2.815"
          ry="2.815"
          transform="rotate(-120 72.111 87.566)"
        ></ellipse>
        <ellipse
          cx="16.166"
          cy="16.169"
          fill="#000"
          fillOpacity="0.12"
          rx="1.407"
          ry="1.407"
          transform="rotate(-135 16.166 16.169)"
        ></ellipse>
        <circle
          r="1.407"
          fill="#000"
          fillOpacity="0.12"
          transform="rotate(-135 60.691 25.14)"
        ></circle>
        <ellipse
          cx="28.109"
          cy="28.111"
          fill="#000"
          fillOpacity="0.8"
          rx="4.222"
          ry="4.222"
          transform="rotate(-135 28.11 28.11)"
        ></ellipse>
        <ellipse
          cx="73.888"
          cy="73.89"
          fill="#000"
          fillOpacity="0.8"
          rx="4.222"
          ry="4.222"
          transform="rotate(-135 73.888 73.89)"
        ></ellipse>
        <ellipse
          cx="8.34"
          cy="26.371"
          fill="#000"
          fillOpacity="0.12"
          rx="1.407"
          ry="1.407"
          transform="rotate(-150 8.34 26.371)"
        ></ellipse>
        <ellipse
          fill="#000"
          fillOpacity="0.12"
          rx="1.407"
          ry="1.407"
          transform="rotate(-150 56.962 25.267)"
        ></ellipse>
        <ellipse
          cx="14.435"
          cy="29.889"
          fill="#000"
          fillOpacity="0.48"
          rx="2.815"
          ry="2.815"
          transform="rotate(-150 14.435 29.89)"
        ></ellipse>
        <ellipse
          fill="#000"
          fillOpacity="0.48"
          rx="2.815"
          ry="2.815"
          transform="rotate(-150 53.444 24.324)"
        ></ellipse>
        <ellipse
          cx="3.417"
          cy="38.251"
          fill="#000"
          fillOpacity="0.12"
          rx="1.407"
          ry="1.407"
          transform="rotate(-165 3.417 38.25)"
        ></ellipse>
        <ellipse
          fill="#000"
          fillOpacity="0.12"
          rx="1.407"
          ry="1.407"
          transform="rotate(-165 53.486 25.386)"
        ></ellipse>
        <ellipse
          cx="19.731"
          cy="42.622"
          fill="#000"
          fillOpacity="0.8"
          rx="4.222"
          ry="4.222"
          transform="rotate(-165 19.731 42.622)"
        ></ellipse>
        <ellipse
          cx="82.265"
          cy="59.378"
          fill="#000"
          fillOpacity="0.8"
          rx="4.222"
          ry="4.222"
          transform="rotate(-165 82.265 59.378)"
        ></ellipse>
        <circle
          cx="51"
          cy="51"
          r="21.333"
          fill="#000"
          fillOpacity="0.48"
        ></circle>
        <circle cx="51" cy="51" r="14.667" fill="#000"></circle>
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M44.583 50.333c0 1.892.83 3.59 2.144 4.75h8.379a6.333 6.333 0 10-10.523-4.75zm6.727-.985l2.19.81-2.19.81-.81 2.19-.81-2.19-2.19-.81 2.19-.81.81-2.19.81 2.19zm1.69-3.19l.405 1.095 1.095.405-1.095.405L53 49.158l-.406-1.095-1.094-.405 1.094-.405.406-1.095z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#fff"
          d="M55.666 56.25h-9.333L45.166 58h11.667l-1.167-1.75z"
        ></path>
      </svg>
    </>
  );
};
