import logo from "../assets/img/The-Greens-NFT-Club-Logo.png";
import { useJoinWaitListProvider } from "../contexts/JoinWaitListProvider";

const Navbar = () => {
  const { JoinWaitListRef } = useJoinWaitListProvider();
  const handleScrollToJoinWaitList = () => {
    JoinWaitListRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  return (
    <>
      <div className="navbar_fixed_top">
        <div className="container-fluid">
          <div className="row align-items-center justify-content-between">
            <div className="col-auto">
              <div className="logo-style mt-3">
                <a href="https://waitlist.greensnftclub.com/">
                  <img src={logo} alt="logo" />
                </a>
              </div>
            </div>
            <div className="col-auto mt-3">
              <a
                href="https://discord.gg/UTz7VVhF"
                target="_blank"
                rel="noopener noreferrer"
                className="faq_text cursor-pointer text-decoration-none"
              >
                FAQ
              </a>
              <button
                className="btn ms-sm-4 ms-2 common_btn rounded-pill"
                onClick={handleScrollToJoinWaitList}
              >
                Join waitlist
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
