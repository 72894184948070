import { useState, useContext, createContext, useRef } from "react";
import { uid } from "uid";
import { set, ref, get, onValue } from "firebase/database";
import emailjs from "emailjs-com";
import { init } from "emailjs-com";
import { db } from "../firebase";
import { toast } from "react-toastify";
init("lyvYYy43oqdZl0gKp");

const JoinWaitListProviderContext = createContext();

export function useJoinWaitListProvider() {
  return useContext(JoinWaitListProviderContext);
}

export default function JoinWaitListProvider({ children }) {
  const EmailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const JoinWaitListRef = useRef();
  const [isShowThanksElement, setIsShowThanksElement] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [isCheckedPrivacy, setCheckedPrivacy] = useState(false);

  const initialState = () => {
    setLoading(false);
    setCheckedPrivacy(false);
    setEmail("");
    setIsShowThanksElement(false);
  };
  // FIRST CHECK EMAIL EXISTS ON DATABASE OR NOT IF EXITS SHOW ALERT ELSE SAVE DATA TO DATABASE AND SHOW THANKS MESSAGE + CALL EMAILJS FUNCTION

  const saveEmailHandler = async () => {
    if (email !== "" && EmailRegex.test(email) === true) {
      setLoading(true);
      let docName = email.split("@")[0];
      let docRef = ref(db, "/waitlist/" + docName);
      let doc = await get(docRef);
      if (doc.exists()) {
        // IF EMAIL ALREADY EXISTS SHOW TOASTIFY
        toast.error("Email already exists in the waitlist", {
          position: "bottom-right",
        });
        initialState();
      } else {
        onValue(
          ref(db, "/waitlist/"),
          async (snapshot) => {
            if (snapshot.exists()) {
              let r = Object.keys(await snapshot.val()).length;
              await setData(docRef, email, r + 1);
            } else {
              await setData(docRef, email, 1);
            }
          },
          (error) => {
            alert(error);
          },
          {
            onlyOnce: true,
          }
        );
      }
    }
  };

  const setData = async (ref, email, rank) => {
    let uuid = uid();
    set(ref, {
      email: email,
      uuid: uuid,
      createdAt: new Date(),
      isVerified: false,
      rank: rank,
    });
    emailSendHandler({ uuid });
  };

  // EMAIL SEND HANDLER TAKES UUID AS PARAMETER AND SEND EMAIL TO THE USER

  const emailSendHandler = async ({ uuid }) => {
    const slug = email.split("@")[0];
    emailjs
      .send("service_1tiyhuq", "template_xvcybyr", {
        email: email.trim(),
        reply_to: email.trim(),
        id: uuid,
        slug: slug,
      })
      .then(() => {
        setLoading(false);
        setCheckedPrivacy(false);
        setEmail("");
        setIsShowThanksElement(true);
      })
      .catch(() => {
        initialState();
      });
  };
  const value = {
    isLoading,
    email,
    setEmail,
    saveEmailHandler,
    setCheckedPrivacy,
    isCheckedPrivacy,
    EmailRegex,
    isShowThanksElement,
    JoinWaitListRef,
  };

  return (
    <JoinWaitListProviderContext.Provider value={value}>
      {children}
    </JoinWaitListProviderContext.Provider>
  );
}
