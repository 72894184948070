import React from "react";

const Footer = () => {
  return (
    <>
      <footer className="d-flex justify-content-between justify-content-sm-start align-items-center p-2 p-sm-3">
        <span>
          <a
            className="footer-links"
            href="https://discord.gg/UTz7VVhF"
            rel="noopener noreferrer"
            target="_blank"
          >
            <span>Terms &amp; Conditions</span>
          </a>
        </span>
        <span>
          <a
            className="footer-links mx-0 mx-sm-4"
            href="https://discord.gg/UTz7VVhF"
            rel="noopener noreferrer"
            target="_blank"
          >
            <span>Privacy Policy</span>
          </a>
        </span>
        <span>
          <a
            className="footer-links"
            href="https://discord.gg/UTz7VVhF"
            rel="noopener noreferrer"
            target="_blank"
          >
            <span>Third Party Disclosures</span>
          </a>
        </span>
        <span className="footer-links ms-3">©&nbsp;2022</span>
      </footer>
    </>
  );
};

export default Footer;
