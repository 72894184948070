import React from "react";
import { ThanksSIgningUp, JoinWaitList } from "../components";
import { useJoinWaitListProvider } from "../contexts/JoinWaitListProvider";

const Home = () => {
  const { isShowThanksElement } = useJoinWaitListProvider();

  return <>{isShowThanksElement ? <ThanksSIgningUp /> : <JoinWaitList />}</>;
};

export default Home;
