import React from "react";
import { useJoinWaitListProvider } from "../contexts/JoinWaitListProvider";
import { JoinWaitlistBulbIcon } from "./icons/Icons";
import Loader from "./common/Loader";

const JoinWaitList = () => {
  const {
    isLoading,
    email,
    setEmail,
    isCheckedPrivacy,
    setCheckedPrivacy,
    saveEmailHandler,
    EmailRegex,
    JoinWaitListRef,
  } = useJoinWaitListProvider();

  return (
    <>
      <section
        className="py-0 py-sm-5 my-5 overflow-x-hidden"
        ref={JoinWaitListRef}
      >
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-xl-5 col-md-8 col-lg-7 col-sm-10">
              <div className="d-flex flex-column flex-md-row">
                <span className="join-waitlist-bubble-svg">
                  <JoinWaitlistBulbIcon />
                </span>
                <div>
                  <h2 className="heading-style mb-4">
                    Join the waitlist for early access!
                  </h2>
                  <h6 className="small-text-grey">
                    After you're on the waitlist, you'll get a confirmation
                    link. Be first in line for early access. The earlier you
                    signup, the higher up on the list you will be!
                  </h6>

                  <input
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    className="email-input"
                    type="text"
                    placeholder="Email"
                  />
                  <div className="d-flex align-items-center privacy-checkbox my-3">
                    <label className="container-checkbox">
                      Yes, please send me the latest news about Greens NFT Club.
                      <br className="d-none d-sm-block" /> I understand I can
                      unsubscribe at any time.
                      <a
                        rel="noreferrer"
                        href="https://discord.gg/UTz7VVhF"
                        target="_blank"
                      >
                        &nbsp;Privacy Policy
                      </a>
                      <input
                        onChange={(e) => setCheckedPrivacy(e.target.checked)}
                        checked={isCheckedPrivacy}
                        type="checkbox"
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>

                  <button
                    className={`join-waitlist-btn ${
                      EmailRegex.test(email) && isCheckedPrivacy
                        ? ""
                        : "disabled"
                    }`}
                    onClick={saveEmailHandler}
                  >
                    {isLoading ? <Loader /> : "Join waitlist"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default JoinWaitList;
