import React from "react";

const ThanksSIgningUp = () => {
  return (
    <>
      <section
        className="py-5 position-relative my-5"
        data-aos="fade-left"
        data-aos-duration="700"
        data-aos-delay="500"
      >
        <div className="container">
          <div className="row justify-content-center ">
            <div className="col-xl-4 col-md-5 col-sm-6 ">
              <h2 className="heading-style mb-4">Thanks for signing up!</h2>
              <h6 className="small-text-grey">
                A verification email has been sent to you. Please verify your
                email to secure your spot on the waitlist.
              </h6>
            </div>
            <div className="col-xl-3 col-sm-6  col-md-5 mt-minus-20">
              <div className="d-flex mt-3 mt-sm-0 flex-column">
                <div className="text-start cursor-pointer ">
                  <span className="btn_modified py-2 px-3">
                    You'll be notified
                  </span>
                </div>
                <div className="text-end mt-4 ">
                  <span className="btn_comint_soon py-2 px-3 cursor-pointer ">
                    Coming soon!
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ThanksSIgningUp;
