import React from "react";
import firstimg from "../assets/img/Augusta.png";
import secondimg from "../assets/img/Pebble-Beach.png";
import thirdimg from "../assets/img/Pinehurst.png";
import fourthimg from "../assets/img/StAndrews.png";
import fiveimg from "../assets/img/TPC-Sawgrass.png";

const Header = () => {
  return (
    <>
      <div className="container create_section_height mt_min_50">
        <div
          className="row justify-content-center"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="200"
        >
          <div className="col-6 col-lg-8 text-center">
            <div>
              <span className="ms-sm-5 ms-lg-4 d-inline-block common_head_text">
                NFT.
              </span>
            </div>
          </div>
        </div>
        <div
          className="row"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="300"
        >
          <div className="col-lg-7 d-flex justify-content-center ">
            <span className="margin-left-m-125 d-inline-block text-left common_head_text  ms-sm-5">
              GOLF.
            </span>
          </div>
        </div>
        <div
          className="row justify-content-center justify-content-sm-end"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="400"
        >
          <div className="col-8 col-lg-7">
            <div className="text-end text-sm-center">
              <span className="common_head_text me-lg-5">CLUB.</span>
            </div>
          </div>
        </div>
        <div className="row justify-content-end">
          <div className="col-sm-7 col-xl-6 d-flex d-sm-block flex-column align-items-center">
            <h2
              className="coming_soon_text ms-5 ms-sm-0"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="500"
            >
              Coming soon!
            </h2>
            <h2
              className="join_text ms-5 ms-sm-0"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="600"
            >
              Join the waitlist to get
              <span className="d-block">early access.</span>
            </h2>
          </div>
        </div>
      </div>
      <div className="page2 my-sm-5 py-sm-3 overflow-x-md-hidden">
        <div
          className="nft-section"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="500"
        >
          {/* first img  */}
          <div className="first_img_section">
            <div className="first_sub">
              <img className="first_img_sub" src={firstimg} alt="firstimg" />
            </div>
          </div>
          {/* second img */}
          <div className="second_img_section">
            <div className="second_sub">
              <img className="second_img_sub" src={secondimg} alt="secondimg" />
            </div>
          </div>
          {/* third img */}
          <div className="third_img_section">
            <div className="third_sub">
              <img className="third_img_sub" src={thirdimg} alt="thirdimg" />
            </div>
          </div>
          {/* fourth img */}
          <div className="four_img_section">
            <div className="four_sub">
              <img className="four_img_sub" src={fourthimg} alt="fourthimg" />
            </div>
          </div>
          {/* fifth img */}
          <div className="five_img_section">
            <div className="five_sub">
              <img className="five_img_sub" src={fiveimg} alt="fiveimg" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
