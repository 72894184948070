import React, { useEffect, useState } from "react";
import { ShareReferral } from "../components";
import { withRouter } from "react-router-dom";
import { getDatabase, ref, get } from "firebase/database";

const Share = ({ match }) => {
  const [rank, setRank] = useState(0);
  const bottomRef = React.useRef();

  useEffect(() => {
    window.scrollTo(0, bottomRef.current.offsetTop);
  }, []);

  useEffect(() => {
    // GET PARTICULAR DOCUEMNT FROM REALTIME DATABASE BY ID
    const getData = async () => {
      const params = match.params.id;
      let db = getDatabase();
      let docName = params.split(".")[1];
      let docRef = ref(db, "/waitlist/" + docName);
      let doc = await get(docRef);
      console.log(doc.val(), "doc");
      setRank(doc.val().rank);
    };
    getData();
  }, [match.params.id]);

  return (
    <>
      <ShareReferral bottomRef={bottomRef} rank={rank} />
    </>
  );
};

export default withRouter(Share);
