import React from "react";

const ShareReferral = ({ bottomRef, rank }) => {
  return (
    <section className="py-5 position-relative my-5" ref={bottomRef}>
      <div
        className="container"
        data-aos="fade-up"
        data-aos-duration="700"
        data-aos-delay="500"
      >
        <div className="row justify-content-center ">
          <div className="col-xl-4 col-md-5 col-sm-6 ">
            <h2 className="heading-style mb-4">Your spot has been secured.</h2>
            <h6 className="small-text-grey">
              Congratulations on completing the first step to joining one of the
              most exclusive NFT Clubs. Depending on your ranking, you will
              be notified of further updates when available.
            </h6>
          </div>
          <div className="col-xl-3 col-sm-6  col-md-5 mt-minus-20">
            <div className="d-flex mt-3 mt-sm-0 flex-column">
              <div className="">
                <span className="btn_modified py-2 px-3 ">
                  You'll be notified
                </span>
              </div>
              <div className="text-end mt-4 ">
                <span className="btn_comint_soon py-2 px-3">Coming soon! </span>
              </div>
              <div className="d-flex w-100 mt-5 justify-content-center">
                <div className="btn_modified   py-2 px-3">
                  Your rank:
                  <span className="d-block fw-bold fs-md mt-3 text-center">
                    {rank}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ShareReferral;
